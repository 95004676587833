<template>
  <!-- this header -->
  <header style="background-color: papayawhip" class="p-2 border-b-2">
    <div class="wrap-header">
      <table class="w-full text-gray-500">
        <tr>
        <td class="w-1/3 text-left">
         <button type="button">
           <Icon icon="ci:help-circle-outline" @click="showModal = !showModal" height="24"/>
        </button>

<!--         <button type="button" class="ml-1">
           <Icon icon="clarity:settings-line" @click="showSettings = !showSettings" height="24"/>
        </button>
 -->
      </td>
        <td class="w-1/3 text-center text-xl text-black text-top"><h1>FRUMÞÁTTUN</h1></td>
        <td class="w-1/3 text-right">

<!--           <button v-if="$i18n.locale != 'celeb'" type="button" class="mr-2">
            <Icon v-if="$i18n.locale == 'is'" icon="emojione:flag-for-iceland" height="22" @click="showLang= !showLang"/>
            <Icon v-else-if="$i18n.locale == 'en'" icon="emojione:flag-england" height="22" @click="showLang= !showLang"/>
            <Icon v-else-if="$i18n.locale == 'da'" icon="emojione:flag-for-denmark" height="22" @click="showLang= !showLang"/>
          </button>
 -->
 
<!--           <button type="button" v-if="!eyedleuser || !eyedleuser.loggedIn">
            <Icon icon="gridicons:stats" @click="showStats = !showStats" height="24"/>
          </button>
          <button type="button" v-else>
            <Icon icon="gridicons:stats" @click="$store.dispatch('setGroups'); showStats = !showStats"  height="24"/>
          </button>
 -->
          <button
            v-if="!eyedleuser || !eyedleuser.loggedIn"
            class="ml-1"
          >
            <Icon icon="healthicons:ui-user-profile-outline" @click="showLogin = !showLogin" height="24"/>
          </button>

          <button class="ml-1" v-else>
            <Icon icon="material-symbols:group-add" @click="$store.dispatch('setGroups'); showProfile = !showProfile" height="24"/>
          </button>

        </td>
        </tr>
        </table>
<!--          <img 
              style="max-width: 200px"
              src="../assets/img/beepr-logo_1.svg"
              class="ml-1"
              alt=""
            />
        <h2
          class="lg:block hidden px-20 p-0"
        >         
        </h2>
 --> 
    </div>
  </header>

   <ModalDialog :show="showModal"></ModalDialog>

   <SettingsDialog :show="showSettings"></SettingsDialog>

   <LangDialog :show="showLang"></LangDialog>

   <StatsDialog :show="showStats"></StatsDialog>

   <Login-modal :show="showLogin"></Login-modal>

   <ProfileDialog :show="showProfile"></ProfileDialog>

</template>

<script>
  import { mapGetters } from "vuex";
  import { auth } from "../firebaseDb";
  import LoginModal from "@/views/components/LoginModal.vue";
  import ModalDialog from '@/views/components/ModalDialog.vue';
  import StatsDialog from '@/views/components/StatsDialog.vue';
  import ProfileDialog from '@/views/components/ProfileDialog.vue';
  import LangDialog from '@/views/components/LangDialog.vue';
  import SettingsDialog from '@/views/components/SettingsDialog.vue';
  import { Icon } from "@iconify/vue";
  import { ref } from 'vue';

  export default {
    data() {
      return {
        showSelect: false,
      };
    },
    setup() {
      const showLang = ref(false);
      const showModal = ref(false);
      const showStats = ref(false);
      const showProfile = ref(false);
      const showLogin = ref(false);
      const showSettings = ref(false); 
      return {
        showLang,
        showModal,
        showStats,
        showProfile,
        showLogin,
        showSettings
      };
    },
    methods: {
      logout() {
        auth.logout();
      },
    },
    watch: {
       loggedIn: function (val) {
        //console.log(val);
        //console.log(this.joinID);
        //console.log(this.showLogin);
        if (val && this.joinID) {
          //console.log("show profile");
          //this.showLogin = false;
          this.showProfile = true;
        } else if(this.joinID) {
          //this.showLogin = true;
        }
      },
      joinID: function (val) {
        //console.log(val);
        //console.log(this.loggedIn);
        if (val && !this.loggedIn) {
          //console.log("show login");
          this.showLogin = true;
        } else if (val && this.loggedIn) {
          //console.log("show profile");
          this.showProfile = true;
        }
      },
    },
    computed: {
      ...mapGetters({
        loggedIn: "loggedIn",
        eyedleuser: "eyedleuser",
        joinID: "joinID",
        groups: "groups",
      }),
    },
    components: {
      Icon,
      ModalDialog,
      StatsDialog,
      ProfileDialog,
      LangDialog,
      SettingsDialog,
      LoginModal,
    },
  };
</script>
