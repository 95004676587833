<template>
<!--    <vpr :onRefresh="onRefresh">
 -->  
 <div class="card px-2 py-2 rounded-md bg-white justify-center w-full grow"
  >
    <div class="card bg-white w-full">

      <div class="text-center text-xl text-bold mt-5 mb-5">
        <div>
            <label for="prime" class="block mb-2 text-lg font-medium text-gray-900 dark:text-gray-300">Frumþáttaðu</label>
            <input v-model="prime" type="number" name="prime" id="prime"
              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="128" required>
          </div>
      </div>

      <div v-if="prime">
        <table class="w-full">
          <tr>
            <th>Frumtala</th>
            <th>Rest</th>
          </tr>
          <tr v-for="p,i in primeFactors[0]" :key="p+i">
            <td class="text-center">{{ p }}</td>
            <td class="text-center">{{ primeFactors[1][i] }}</td>
          </tr>
        </table>
      </div>
<!--       <SimpleKeyboard @onChange="onChange" :input="input" />
 -->
      <div v-if="!nativeKeyboard && input && input.length>0" class="text-center mt-2">Searching for: {{ input.toUpperCase() }}</div>
 
      </div>
    </div>
<!--   <GuessDialog :show="showGuess" :guesses="guesses[date]"></GuessDialog>
 -->
</template>

<script>
// @ is an alias to /src
import GuessDialog from "@/views/components/GuessDialog.vue";
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import { ref } from "vue";
import SimpleKeyboard from "./components/SimpleKeyboard.vue";

export default {
  name: "Frumþáttun",

  setup() {
    const showGuess = ref(false);
    return {
      showGuess,
    };
  },

  data() {
    return {
      prime: 0,
      now: new Date(),
      newYear: new Date().setHours(24,0,0,0),
      input: ref(""),
      countries: [],
      answer: [],
      selectedCountry: ref(""),
      showKeyboard: true,
    };
  },
  components: {
    Icon,
    SimpleKeyboard,
    GuessDialog,
    //vpr
  },
  methods: {
    copy(guessNr) {
      let text = [
        this.$i18n.t("share.first"),
        this.$i18n.t("share.second"),
        this.$i18n.t("share.third"),
        this.$i18n.t("share.fourth"),
        this.$i18n.t("share.fifth"),
        this.$i18n.t("share.sixth"),
      ];
      navigator.clipboard.writeText(text[guessNr-1]);
      this.$notify(
        { group: "foo", title: this.$i18n.t("general.copied"), text: this.$i18n.t("general.sharetext") },
        2000
      ); // 2s
    },
    onChange(input) {
      this.input = input;
      this.$store.dispatch("setSearchWords", this.input);
    },
    onChangeNative(event) {
      this.input = event.target.value;
      //console.log(event)
      this.$store.dispatch("setSearchWords", this.input);
    },
   selectCountry(country) {
      this.selectedCountry = country;
      this.input = "";
      this.$store.commit("ADD_GUESS", country);
      this.$store.dispatch("setStatus");
    },
  },
  computed: {
    ...mapGetters({
      eyedleuser: "eyedleuser",
      stats: "stats",
      date: "date",
      status: "status",
      game: "game",
      words: "words",
      theanswer: "answer",
      searchWords: "searchWords",
      nativeKeyboard: "nativeKeyboard",
    }),
    //Function to calculate the prime factors of a given number
    primeFactors() {
      let n = this.prime;
      //console.log("called primeFactors with n=" + n + "");
      var factors = [],
        reminders = [],
        divisor = 2;

      while (n >= 2) {
        if (n % divisor == 0) {
          factors.push(divisor);
          n = n / divisor;
          reminders.push(n);
        } else {
          divisor++;
        }
      }
      return [factors, reminders];
    },

    time() {
      return this.newYear - this.now;
    },
  },
  mounted() {
      if (this.$route.query.join && this.$route.query.join.length > 0) {
        this.$store.dispatch("setJoinID", this.$route.query.join);
        this.$store.dispatch("setJoinName", this.$route.query.name);
      }
  },
  async created() {
/*     await import("./data/"+this.game+"-answer.json").then((module) => {
      this.answer = module.default;
    });
    this.$store.dispatch("setAnswer", this.answer[this.date]);
    this.$store.dispatch("setStatus");

    await import("./data/" +this.game+ ".json").then((module) => {
      this.countries = module.default;
    });
    //console.log(this.game);
    this.$store.dispatch("setWords", this.countries);
 */  },
};
</script>

<style type="text/css">
 .simple-keyboard {
  max-width: 850px;
  margin: auto;
}

 .simple-keyboard .hg-button {
  -webkit-tap-highlight-color: rgba(201, 224, 253, 0.8);
/*     -webkit-tap-highlight-color: grey !important;
    -webkit-tap-highlight-color:transparent;
    -webkit-user-select: none;
 */ }

 html {
  -webkit-tap-highlight-color: rgba(201, 224, 253, 0.8);
}

.thumbnail {
  width: 85vw;
  /*max-height: 40vh;*/
  margin:auto;
  margin-bottom: 10px;
}
.image {
  max-width: 100%;
    max-height: 100%;
    display: block;
    height: auto;
    margin: 0 auto;
    align-items: center
}

</style>
