import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { getAnalytics, logEvent, setCurrentScreen, setUserId, setUserProperties } from "firebase/analytics"

import {
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  //onAuthStateChanged,
} from "firebase/auth";

import store from "./store/store";
import router from "./router";

const firebaseConfig = {
  apiKey: "AIzaSyAFaqXZ1g4xLljpvmFvUdiY6HBPwaH0uPY",
  authDomain: "frumthattun.firebaseapp.com",
  projectId: "frumthattun",
  storageBucket: "frumthattun.appspot.com",
  messagingSenderId: "281853064385",
  appId: "1:281853064385:web:6abd3d80d9070610f59040",
  measurementId: "G-385F2R0Y77"
};

const firebaseApp = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const theauth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
const theanalytics = getAnalytics(firebaseApp);

export const analytics = {
  analytics: theanalytics,
  setUserId(id) {
    setUserId(theanalytics, id);
  },
  setUserProperties(props) {
    setUserProperties(theanalytics, props)
  },
  logEvent(name, params) {
    logEvent(theanalytics, name, params);
  },
  setCurrentScreen(name){
    setCurrentScreen(theanalytics, name);
  }
}

export const auth = {
  auth: theauth,
  signUpWithEmail(name, email, password) {
    return new Promise((resolve, reject) => {
      createUserWithEmailAndPassword(theauth, email, password)
        .then((res) => {
          //New User Document
          const docRef = doc(db, "users", res.user.uid);

          setDoc(docRef, {
            Navn: name,
            Email: email,
            DefaultShoppingList: res.user.uid,
          })
            .then(() => {
              store
                .dispatch("fetchUser", res.user)
                .then((eyedleuser) => {
                  if (eyedleuser) {
                    resolve("ok");
                  }
                })
                .catch((error) => {
                  console.log("1:" + error.message);
                  resolve(error.message);
                });
            })
            .catch((error) => {
              console.log("2:" + error.message);
              resolve(error.message);
            });
        })
        .catch((error) => {
          console.log("3:" + error.message);
          resolve(error.message);
        });
    });
  },
  login() {
    signInWithPopup(theauth, provider)
      .then((res) => {
        const docRef = doc(db, "users", res.user.uid);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            store
              .dispatch("fetchUser", res.user)
              .then((eyedleuser) => {
                //this.$forceUpdate();
                //methodThatForcesUpdate();
                //if (eyedleuser) {
                //  router.push({ name: "home" }).catch((err) => {
                //    throw new Error(`Problem handling router push: ${err}.`);
                //  });
                //}
              })
              .catch((error) => {
                console.log(error.message);
              });
          } else {
            setDoc(docRef, {
              name: res.user.displayName,
              email: res.user.email,
            }).then(() => {
              store
                .dispatch("fetchUser", res.user)
                .then((eyedleuser) => {
                  //this.$forceUpdate();
                  //methodThatForcesUpdate();
                  //  if (eyedleuser) {
                //    router.push({ name: "home" }).catch((err) => {
                //      throw new Error(`Problem handling router push: ${err}.`);
                //    });
                //  }
                })
                .catch((error) => {
                  console.log(error.message);
                });
            });
          }
        });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, email, credential);
      });
  },
  loginEmail(email, password, name) {
    return new Promise((resolve, reject) => {
      signInWithEmailAndPassword(theauth, email, password)
        .then((res) => {
          //store.commit("SET_USER", res.user);
          store.dispatch("fetchUser", res.user).then((eyedleuser) => {
            if (eyedleuser) {
              //if(eyedleuser.data && eyedleuser.data.client && eyedleuser.data.client == "beepr") {
              //router.push({name:"report"}).catch((err) => {
              //  throw new Error(`Problem handling router push: ${err}.`);
              //});
              resolve("ok");
              //} else {
              //router.push({name:"home"}).catch((err) => {
              //  throw new Error(`Problem handling router push: ${err}.`);
              //});
              resolve("ok");
              //}
            } else {
              console.log("No eyedleuser");
              console.log(eyedleuser);
            }
          });
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  },
  resetEmail(email) {
    return new Promise((resolve, reject) => {
      sendPasswordResetEmail(theauth, email)
        .then(() => {
          resolve("ok");
        })
        .catch((error) => {
          resolve(error.message);
        });
    });
  },
  logout() {
    signOut(theauth)
      .then(function () {
        store.commit("SET_USER", {}, false);
        router.push("/").catch((err) => {
          throw new Error(`Problem handling router push: ${err}.`);
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
