import { createStore } from 'vuex'
import { db } from "../firebaseDb";
import {
  doc,
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  query,
  where
} from "firebase/firestore";

var dateObj = new Date();
var day = ('0' + (dateObj.getDate())).slice(-2);
var month = ('0' + (dateObj.getMonth() + 1)).slice(-2);
var year = dateObj.getFullYear();

const store = createStore({

  state: {
    loggedIn: null,
    joinID: "",
    joinName: "",
    game: "celeb",
    answer: "",
    date: year + month + day,
    guesses: {},
    stats: {},
    status: "",
    locale: "",
    words: [],
    searchWords: [],
    nativeKeyboard: false,
    groups: null,
    eyedleuser: {
      loggedIn: false,
      data: null,
      uid: "",
    },
  },

  getters: {
    eyedleuser(state) {
      return state.eyedleuser;
    },
    nativeKeyboard(state) {
      //console.log(navigator.userAgent)
      let g = localStorage.getItem('eyedlekeyboard');
      //console.log(g);
      if (g && g == "native") {
        state.nativeKeyboard = true;
        return state.nativeKeyboard;
      } else if (g && g == "virtual") {
        state.nativeKeyboard = false;
        return state.nativeKeyboard;
      } else {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
          state.nativeKeyboard = true;
          return state.nativeKeyboard;
        } else {
          state.nativeKeyboard = false;
          return state.nativeKeyboard;
        }
      }
    },
    joinID(state) {
      return state.joinID;
    },
    joinName(state) {
      return state.joinName;
    },
    loggedIn(state) {
      return state.loggedIn;
    },
    date(state) {
      return state.date;
    },
    game(state) {
      return state.game;
    },
    answer(state) {
      return state.answer;
    },
    status(state) {
      return state.status;
    },
    words(state) {
      return state.words;
    },
    searchWords(state) {
      return state.searchWords;
    },
    groups(state) {
      return state.groups;
    },
    locale(state) {
      let g = localStorage.getItem('eyedlelocale');
      //console.log("A:" + g);
      if (g) {
        state.locale = g;
        return g;
      } else {
        return "";
      }
    },
    stats(state) {
      return state.stats;
    },
    guesses(state) {
      return state.guesses;
    },
  },

  actions: {
    setJoinID({ commit }, id) {
      commit("SET_JOIN_ID", id);
    },
    setJoinName({ commit }, id) {
      commit("SET_JOIN_NAME", id);
    },
    updateGroupStats({ state }, stats) {
      var update = {};
      update[`stats.${state.eyedleuser.uid}`] = stats;
      Object.keys(state.groups).forEach((key) => {
        const docRef = doc(db, "groups", key);
        updateDoc(docRef, update)
      });
    },
    joinGroup({ dispatch }, group) {
      var update = {};
      update["users"] = arrayUnion(group.uid);
      update[`stats.${group.uid}`] = group.stats;
      update[`names.${group.uid}`] = group.name;
      const docRef = doc(db, "groups", group.id);
      updateDoc(docRef, update).then(() => {
        dispatch("setGroups");
        dispatch("setJoinID", "");
        dispatch("setJoinName", "");
      });
    },
    async createGroup({ dispatch }, group) { 
      let stats = {};
      stats[group.uid] = group.stats;
      await addDoc(collection(db, "groups"), {
        name: group.name,
        users: [group.uid],
        stats: stats,
        names: group.names,
      });
      dispatch("setGroups");
    },
    async setGroups({ state, commit }) {
      if(!state.eyedleuser || !state.eyedleuser.loggedIn) return;
      //console.log("setGroups");
      let tmpGroups = {};
      const q = query(collection(db, "groups"), where("users", "array-contains", state.eyedleuser.uid));
      const snapshot = await getDocs(q);
      snapshot.forEach(doc => {
        //console.log(doc.id, '=>', doc.data());
        tmpGroups[doc.id] = doc.data();
      });
      commit("SET_GROUPS", tmpGroups);
    },
    fetchUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      return new Promise((resolve, reject) => {
        try {
          if (user) {
            commit("SET_UID", user.uid);

            const docRef = doc(db, "users", user.uid);
            getDoc(docRef).then((docSnap) => {
              if (docSnap.exists()) {
                const eyedleuser = docSnap.data();
                eyedleuser.uid = user.uid;
                //commit("SET_CLIENT", eyedleuser.client);
                commit("SET_USER", eyedleuser);
                resolve(eyedleuser);
              } else {
                console.log("No such user in fetch user!");
                resolve({});
              }
            });
          } else {
            resolve({});
          }
        } catch (error) {
          //console.log(error);
          commit("SET_USER", null);
          resolve({});
        }
      });
    },
    setGuesses({ state, commit }) {
      let lsGuesses = JSON.parse(localStorage.getItem('eyedleguesses'));
      let n = { [state.date]: [] }

      if (state.eyedleuser.loggedIn) {
        const docRef = doc(db, "users", state.eyedleuser.uid);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            const eyedleuser = docSnap.data();
            let guesses = state.eyedleuser.data.guesses;

            if (!guesses) {
              if (lsGuesses) {
                if (!lsGuesses[state.date]) {
                  lsGuesses[state.date] = [];
                }
                state.guesses = lsGuesses;
                updateDoc(docRef, {
                  guesses: lsGuesses,
                });
                return lsGuesses;
              } else {
                state.guesses = n;
                updateDoc(docRef, {
                  guesses: n,
                });
                localStorage.setItem("eyedleguesses", JSON.stringify(n));
                return n;
              }
            } else {
              if (!guesses[state.date]) {
                guesses[state.date] = [];
                localStorage.setItem("eyedleguesses", JSON.stringify(n));
                updateDoc(docRef, {
                  guesses: guesses,
                });
              }
              state.guesses = guesses;
              return guesses;
            }
          } else {
            if (lsGuesses) {
              if (!lsGuesses[state.date]) {
                lsGuesses[state.date] = [];
              }
              state.guesses = lsGuesses;
              return lsGuesses;
            } else {
              let n = { [state.date]: [] }
              state.guesses = n;
              localStorage.setItem("eyedleguesses", JSON.stringify(n));
              return n;
            }
          }
        });
      } else {
        if (lsGuesses) {
          if (!lsGuesses[state.date]) {
            lsGuesses[state.date] = [];
          }
          state.guesses = lsGuesses;
          return lsGuesses;
        } else {
          let n = { [state.date]: [] }
          state.guesses = n;
          localStorage.setItem("eyedleguesses", JSON.stringify(n));
          return n;
        }
      }
    },
    setStats({ state, commit }) {
      let lsStats = JSON.parse(localStorage.getItem('eyedlestats'));

      if (state.eyedleuser.loggedIn) {
        const docRef = doc(db, "users", state.eyedleuser.uid);
        getDoc(docRef).then((docSnap) => {
          if (docSnap.exists()) {
            const eyedleuser = docSnap.data();
            let stats = state.eyedleuser.data.stats;

            //console.log(stats);

            if (!stats) {
              if (lsStats) {
                state.stats = lsStats;
                updateDoc(docRef, {
                  stats: lsStats,
                });
                //console.log("B:" + lsStats);
                //return lsStats;
              } else {
                let n = { played: 0, wins: 0, currStreak: 0, maxStreak: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
                state.stats = n;
                updateDoc(docRef, {
                  stats: n,
                });
                localStorage.setItem("eyedlestats", JSON.stringify(n));
                //console.log("C:" + n);
                //return n;
              }
            } else {
              state.stats = stats;
              localStorage.setItem("eyedlestats", JSON.stringify(stats));
              //console.log(stats);
              //return stats;
            }
          } else {
            if (lsStats) {
              state.stats = lsStats;
              //console.log("E:" + lsStats);
              //return lsStats;
            } else {
              let n = { played: 0, wins: 0, currStreak: 0, maxStreak: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
              state.stats = n;
              localStorage.setItem("eyedlestats", JSON.stringify(n));
              //console.log("F:" + n);
              //return n;
            }
          }
        });
      } else {
        if (lsStats) {
          state.stats = lsStats;
          //console.log("G:" + lsStats);
          //return lsStats;
        } else {
          let n = { played: 0, wins: 0, currStreak: 0, maxStreak: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };
          state.stats = n;
          localStorage.setItem("eyedlestats", JSON.stringify(n));
          //console.log("H:" + n);
          //return n;
        }
      }
    },
    setSearchWords({ state, commit }, input) {
      if (input === "") {
        commit("SET_SEARCH_WORDS", []);
      } else {
        let matches = 0;
        //console.log(Object.values(state.words))
        let res = searchBinary(input, state.words, true)
        //console.log(res);

/*         let res = state.words.filter((country) => {
        let names = country.name.split(" ");
        let len = names.length; 
        if(len > 1) {
          if ((names[0].toLowerCase().startsWith(input) || names[len-1].toLowerCase().startsWith(input)) && matches < 50) {
            matches++;
            return country;
          } else if(country.name.toLowerCase().startsWith(input)) {
            matches++;
            return country;
          }
        } else {
        if (names[0].toLowerCase().startsWith(input) && matches < 50) {
          matches++;
          return country;
        }
      }
      });
 */       commit("SET_SEARCH_WORDS", res);
      }

    },
    addGuess({ state, commit }, guess) {
      /*  console.log(state.guesses[state.date]);
       console.log(state.guesses[state.date].length);
       if (state.guesses[state.date].length < 5 && state.status === "inprogress") {
         console.log("Trying to add guess: " + guess)
         commit("ADD_GUESS", guess)
       } */
    },
    setNativeKeyboard({ commit }, value) {
      //console.log("B:"+value)
      commit("SET_NATIVE_KEYBOARD", value);
    },
    setAnswer({ commit }, answer) {
      commit("SET_ANSWER", answer);
    },
    setWords({ commit }, words) {
      commit("SET_WORDS", words);
    },
    setStatus({ state, commit, dispatch }) {
      let currStatus = "inprogress";

      console.log(state.date)
      console.log(state.guesses[state.date].length)
      console.log(state.answer)

      if (state.guesses[state.date] && state.guesses[state.date].length > 0) {
        console.log(state.guesses[state.date][state.guesses[state.date].length - 1].toLowerCase());
        if (state.guesses[state.date][state.guesses[state.date].length - 1].toLowerCase() === state.answer.toLowerCase()) {
          currStatus = state.guesses[state.date].length.toString();
          dispatch("setWon", currStatus);
        } else if (state.guesses[state.date].length == 5) {
          currStatus = "failed"
          dispatch("setFailed");
        }
      }
      console.log(currStatus);
      commit("SET_STATUS", currStatus);
    },
    cleanup({ state, commit, dispatch }) {
      commit("SET_GROUPS", null);
      commit("SET_UID", "");
      commit("SET_LOGGED_IN", false);
      commit("SET_USER", {});
    },

    setFailed( {state, dispatch}) {
      if (!state.stats.lastGame || state.stats.lastGame < state.date) {
        let played = state.stats.played + 1;
        state.stats.played = played;
        state.stats.currStreak = 0;
        state.stats.lastGame = state.date;
        state.stats.lastScore = "6";
        if(state.eyedleuser.loggedIn) {
          updateDoc(doc(db, "users", state.eyedleuser.uid), {
            stats: state.stats
          });
          dispatch("updateGroupStats", state.stats);
        }
        localStorage.setItem("eyedlestats", JSON.stringify(state.stats));
      }
    },
    async setWon({state, dispatch}, num) {
      if (!state.stats.lastGame || state.stats.lastGame < state.date) {
        let played = state.stats.played + 1;
        state.stats.played = played;
        let wins = state.stats.wins + 1;
        state.stats.wins = wins;
        let currStreak = state.stats.currStreak + 1;
        state.stats.currStreak = currStreak;
        let maxStreak = state.stats.maxStreak;
        state.stats.lastGame = state.date;
        state.stats.lastScore = num;
        let numguess = state.stats[num] + 1;
        state.stats[num] = numguess;

        if (currStreak > maxStreak) {
          state.stats.maxStreak = currStreak;
        }
        //console.log(state.eyedleuser);
        if(state.eyedleuser.loggedIn) {
          //console.log("Updating stats");
          await updateDoc(doc(db, "users", state.eyedleuser.uid), {
            stats: state.stats
          });
          //console.log("Updated stats");
          dispatch("updateGroupStats", state.stats);
        }

        //console.log(state.stats);

        localStorage.setItem("eyedlestats", JSON.stringify(state.stats));
      }
    },

  },

  mutations: {
    SET_JOIN_ID(state, value) {
      state.joinID = value;
    },
    SET_JOIN_NAME(state, value) {
      state.joinName = value;
    },
    SET_GROUPS(state, groups) {
      state.groups = groups;
    },
    SET_UID(state, value) {
      state.eyedleuser.uid = value;
    },
    SET_LOGGED_IN(state, value) {
      state.loggedIn = value;
      state.eyedleuser.loggedIn = value;
    },
    SET_USER(state, data) {
      state.eyedleuser.data = data;
    },
    SET_LOCALE(state, locale) {
      state.locale = locale;
      localStorage.setItem("eyedlelocale", locale);
    },
    ADD_GUESS(state, guess) {
      //console.log(state.guesses[state.date]);
      //console.log(state.guesses[state.date].length);
      state.searchWords = [];
      if (state.guesses[state.date].length < 5 && state.status === "inprogress") {
        //console.log("Trying to add guess: " + guess)
        state.guesses[state.date].push(guess);
        if(state.eyedleuser.loggedIn) {
          updateDoc(doc(db, "users", state.eyedleuser.uid), {
            guesses: state.guesses
          });
        }
        localStorage.setItem("eyedleguesses", JSON.stringify(state.guesses));
      }
    },
/*     SET_FAILED( {state, dispatch}) {
      if (!state.stats.lastGame || state.stats.lastGame < state.date) {
        let played = state.stats.played + 1;
        state.stats.played = played;
        state.stats.currStreak = 0;
        state.stats.lastGame = state.date;
        if(state.eyedleuser.loggedIn) {
          updateDoc(doc(db, "users", state.eyedleuser.uid), {
            stats: state.stats
          });
          dispatch("updateGroupStats", state.stats);
        }
        localStorage.setItem("eyedlestats", JSON.stringify(state.stats));
      }
    },
    SET_WON({state, dispatch}, num) {
      if (!state.stats.lastGame || state.stats.lastGame < state.date) {
        let played = state.stats.played + 1;
        state.stats.played = played;
        let wins = state.stats.wins + 1;
        state.stats.wins = wins;
        let currStreak = state.stats.currStreak + 1;
        state.stats.currStreak = currStreak;
        let maxStreak = state.stats.maxStreak;
        state.stats.lastGame = state.date;

        let numguess = state.stats[num] + 1;
        state.stats[num] = numguess;

        if (currStreak > maxStreak) {
          state.stats.maxStreak = currStreak;
        }

        if(state.eyedleuser.loggedIn) {
          updateDoc(doc(db, "users", state.eyedleuser.uid), {
            stats: state.stats
          });
          dispatch("updateGroupStats", state.stats);
        }
        localStorage.setItem("eyedlestats", JSON.stringify(state.stats));
      }
    },
 */    SET_ANSWER(state, data) {
      state.answer = data;
    },
    SET_WORDS(state, data) {
      state.words = data;
    },
    SET_SEARCH_WORDS(state, data) {
      state.searchWords = data;
    },
    SET_GAME(state, data) {
      state.game = data;
    },
    SET_STATUS(state, data) {
      state.status = data;
    },
    SET_NATIVE_KEYBOARD(state, data) {
      //console.log("C:"+ data)
      if (data) {
        localStorage.setItem("eyedlekeyboard", "native");
      } else {
        localStorage.setItem("eyedlekeyboard", "virtual");
      }
      state.nativeKeyboard = data;
    },
  },
});

export default store;

function searchBinary(needle, haystack, case_insensitive) {
  //console.log(haystack);
  if (needle == "") return [];
  var haystackLength = haystack.length;
  var letterNumber = needle.length;
  case_insensitive = (typeof (case_insensitive) === 'undefined' || case_insensitive) ? true : false;
  needle = (case_insensitive) ? needle.toLowerCase() : needle;

  /* start binary search, Get middle position */
  let getElementPosition = findElement(needle, haystack, haystackLength, letterNumber, case_insensitive)
  //console.log(getElementPosition);
  /* get interval and return result array */
  if (getElementPosition == -1) return [];
  let getRangeElement = findRangeElement(needle, haystack, haystackLength, letterNumber, case_insensitive, getElementPosition);
  //console.log(getRangeElement);
  return getRangeElement;

}

function findElement(needle, haystack, haystackLength, letterNumber, case_insensitive) {
  if (typeof (haystack) === 'undefined' || !haystackLength) return -1;
  //console.log(needle);
  var high = haystack.length - 1;
  var low = 0;
  let mid;

  while (low <= high) {
    mid = parseInt((low + high) / 2);
    //console.log(mid);
    var element = haystack[mid].substr(0, letterNumber);
    element = (case_insensitive) ? element.toLowerCase() : element;

    if (element > needle) {
      high = mid - 1;
    } else if (element < needle) {
      low = mid + 1;
    } else {

      return mid;
    }
  }
  return -1;
}

function findRangeElement(needle, haystack, haystackLength, letterNumber, case_insensitive, getElementPosition) {
  let i;
  let start;
  let end;
  //console.log(getElementPosition)
  for (i = getElementPosition; i > 0; i--) {
    let element = (case_insensitive) ? haystack[i].substr(0, letterNumber).toLowerCase() : haystack[i].substr(0, letterNumber);
    if (element != needle) {
      start = i + 1;
      break;
    } else {
      start = 0;
    }
  }

  for (i = getElementPosition; i < haystackLength; i++) {
    let element = (case_insensitive) ? haystack[i].substr(0, letterNumber).toLowerCase() : haystack[i].substr(0, letterNumber);
    //console.log("::"+element+";;")
    if (element != needle) {
      end = i;
      break;
    } else {
      end = haystackLength - 1;
    }
  }
  var result = [];
  let counter = 0;
  for (i = start; i < end; i++) {
    if (counter < 50) {
      result.push(haystack[i])
    } else {
      break;
    }
    counter++;
  }

  //console.log(result);

  return result;
}