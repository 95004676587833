<template>
  <div class="justify-center items-center">
    <div class="overflow-auto h-screen flex flex-col justify-between">
      <Header />
      <router-view />
    </div>

    <notificationGroup group="foo" position="bottom">
      <div class="z-20 fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-end justify-end">
        <div class="max-w-sm w-full">
          <notification v-slot="{notifications}">
            <div class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications" :key="notification.id">
              <div class="flex justify-center items-center w-12 bg-green-500">
                <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                </svg>
              </div>

              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-green-500 font-semibold">{{notification.title}}</span>
                  <p class="text-gray-600 text-sm">{{notification.text}}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

    <notificationGroup group="error" position="bottom">
      <div class="z-20 fixed inset-0 flex px-4 py-6 pointer-events-none p-6 items-end justify-end">
        <div class="max-w-sm w-full">
          <notification v-slot="{notifications}">
            <div class="flex max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden mt-4"
              v-for="notification in notifications" :key="notification.id">
              <div class="flex justify-center items-center w-12 bg-red-500">
                <svg class="h-6 w-6 fill-current text-white" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6166L29.3167 10.9666L31.6667 13.3333L16.6667 28.3333Z" />
                </svg>
              </div>

              <div class="-mx-3 py-2 px-4">
                <div class="mx-3">
                  <span class="text-red-500 font-semibold">{{notification.title}}</span>
                  <p class="text-gray-600 text-sm">{{notification.text}}</p>
                </div>
              </div>
            </div>
          </notification>
        </div>
      </div>
    </notificationGroup>

  </div>
</template>

<script>
// vue Components
//import Sidebar from "@/components/Sidebar";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { auth, analytics } from "./firebaseDb";
import { mapGetters } from "vuex";
//import HelpModal from "@/views/HelpModal.vue";

export default {
  name: "App",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    Header,
    Footer,
  },

  mounted: function () {
    auth.auth.onAuthStateChanged((user) => {
      if (!user) {
      }
      //TODO perhaps check store if this is loaded already from main.js and not call again?
      this.$store.dispatch("fetchUser", user).then((eyedleuser) => {
        if (user && user.uid) {
          analytics.setUserId(eyedleuser.uid);
          analytics.setUserProperties({
            account_email: eyedleuser.email,
            account_name:  eyedleuser.name,
          });
        }
      })
    });

        this.$i18n.locale = "is";
        this.$store.commit("SET_GAME", this.$i18n.locale);
  },

  computed: {
    ...mapGetters({
      locale: "locale",
    }),
  },
};
</script>
