<template>
  <teleport to="body">
    <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0"
      enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-show="showLang" ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
        <div class="flex items-start justify-center min-h-screen pt-20 text-center">
          <transition enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95" enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200" leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95">
            <div style="max-width: 400px" class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
              role="dialog" ref="modal" aria-modal="true" aria-labelledby="modal-headline">
              <!--<slot>I'm empty inside</slot>-->
              <p class="mb-4 font-bold">Select Language</p>

              <div class="locale-switcher">
                <select v-model="$i18n.locale" @change="onChangeLang($event)"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5">
                  <option value="da">Dansk</option>
                  <option value="en">English</option>
                  <option value="is">Íslenska</option>
                </select>
              </div>

            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from 'vue';
import { Icon } from "@iconify/vue";
import { useClickOutside } from "@/composables/useClickOutside"
import { mapGetters } from "vuex";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: 'LangDialog',
  props,
  components: {
    Icon
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showLang = ref(false);

    function closeModal() {
      showLang.value = false;
    }

    onClickOutside(modal, () => {
      if (showLang.value === true) {
        closeModal();
      }
    });

    watch(
      () => props.show,
      show => {
        showLang.value = true;
      },
    );

    return {
      closeModal,
      showLang,
      modal
    };
  },
  methods: {
    async onChangeLang(event) {
      this.$store.commit("SET_LOCALE", event.target.value);
      this.$store.commit("SET_GAME", event.target.value);
/*       let countries = [];
      let answer = "";
      await import("../data/" + event.target.value + ".json").then((module) => {
        countries = module.default;
      });
      await import("../data/" + event.target.value + "-answer.json").then((module) => {
        answer = module.default;
      });
      //console.log(this.game);
      this.$store.dispatch("setWords", countries);
      this.$store.dispatch("setAnswer", answer[this.date]);
      this.$store.dispatch("setStatus");
 */    }
  },

  computed: {
    ...mapGetters({
      date: "date",
    }),
  },

};
</script>