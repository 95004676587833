<template>
  <teleport to="body">
    <transition enter-active-class="transition ease-out duration-200 transform" enter-from-class="opacity-0"
      enter-to-class="opacity-100" leave-active-class="transition ease-in duration-200 transform"
      leave-from-class="opacity-100" leave-to-class="opacity-0">
      <div v-show="showLogin" ref="modal-backdrop" class="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-50">
        <div class="flex items-start justify-center min-h-screen pt-20 text-center">
          <transition enter-active-class="transition ease-out duration-300 transform "
            enter-from-class="opacity-0 translate-y-10 scale-95" enter-to-class="opacity-100 translate-y-0 scale-100"
            leave-active-class="ease-in duration-200" leave-from-class="opacity-100 translate-y-0 scale-100"
            leave-to-class="opacity-0 translate-y-10 translate-y-0 scale-95">
            <div style="max-width: 400px" class="bg-white rounded-lg text-left overflow-hidden shadow-xl p-8 w-5/6"
              role="dialog" ref="modal" aria-modal="true" aria-labelledby="modal-headline">
              <Icon
                  class="float-right"
                  icon="ri:close-circle-line"
                  color="gray"
                  style="margin-top: -25px; margin-right: -25px; cursor: pointer;"
                  height="36"
                  @click="closeModal"
                />

              <div class="py-6 px-6 lg:px-8">
                <form v-if="showForgotForm" class="space-y-4" @submit.prevent="forgetPassword">
                  <h3 class="text-xl font-medium text-gray-900 dark:text-white">Endurstilla lykilorð</h3>
                  <div>
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Netfang</label>
                    <input v-model="user.email" type="email" name="email" id="email"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      placeholder="name@company.com" required>
                  </div>
                  <button type="submit" v-if="user.email.length > 0"
                    class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Endurstilla lykilorð
                  </button>
                  <button v-else
                    class="w-full text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    @click.prevent="$notify({ group: 'error', title: 'Error', text: 'Vinsamlegas fylla út netfang' }, 2000)">
                    Endurstilla lykilorð
                  </button>

                  <p
                    @click="showForgotForm = false"
                    style="color: #07C157; cursor: pointer; float: right"
                    class="forgot-password text-right"
                  >
                    Mundirðu lykilorðið?
                  </p>

                </form>

                <form v-else-if="showSignupForm" class="space-y-4" @submit.prevent="userRegistration">
                  <h3 class="text-xl font-medium text-gray-900 dark:text-white">Skráðu þig í FRUMÞÀTTUN</h3>
                  <div>
                    <label for="name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Nafn</label>
                    <input v-model="user.name" name="name" id="name"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Jón Jónsson" required>
                  </div>
                  <div>
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Netfang</label>
                    <input v-model="user.email" type="email" name="email" id="email"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="jon@gmail.com" required>
                  </div>
                  <div>
                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lykilorð</label>
                    <input v-model="user.password" type="password" name="password" id="password" placeholder="••••••••"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required>
                  </div>
                  <button type="submit" v-if="user.password.length > 0 && user.email.length > 0 && user.name.length > 0"
                    class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Skráðu þig
                  </button>

                  <button v-else
                    class="w-full text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    @click.prevent="$notify({ group: 'error', title: 'Error', text: 'Please provide name, email and password' }, 2000)">
                    Skráðu þig
                  </button>

                </form>

                <form v-else class="space-y-4" @submit.prevent="loginEmail">
                  <h3 class="text-xl font-medium text-gray-900 dark:text-white">Innskráning í FRUMÞÀTTUN</h3>
                  <div>
                    <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Netfang</label>
                    <input v-model="user.email" type="email" name="email" id="email"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="name@company.com" required>
                  </div>
                  <div>
                    <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">Lykilorð</label>
                    <input v-model="user.password" type="password" name="password" id="password" placeholder="••••••••"
                      :state="validatePasswordLength"
                      class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required>
                  </div>
                  <button type="submit" v-if="user.password.length > 0 && user.email.length > 0"
                    class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                    Skráðu þig inn
                  </button>
                  <button v-else
                    class="w-full text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    @click.prevent="$notify({ group: 'error', title: 'Error', text: 'Please provide email and password' }, 2000)">
                    Skráðu þig inn
                  </button>

                   <p
                    @click="showForgotForm = true"
                    style="color: #07C157; cursor: pointer; float: right"
                    class="forgot-password text-right"
                  >
                    Gleymt lykilorð?
                  </p>

                </form>

                <button v-if="!showSignupForm && !showForgotForm"
                  class="mt-5 w-full bg-green-200 hover:bg-green-300 focus:ring-4 focus:outline-none focus:ring-green-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  @click="login">
                  <img src="img/google_logo.webp" class="float-left" width="20" height="20" />
                  Innskráning með Google
                </button>

                <div v-if="!showSignupForm && !showForgotForm" class="mt-4 text-center">
                  Ertu ekki með Frumþáttun notenda?
                  <p @click="showSignupForm = true" style="color: #07C157; cursor: pointer;" class="forgot-password">
                    Skráðu þig hér
                  </p>
                </div>

                <div v-else-if="!showForgotForm" class="mt-4 text-center">
                  Nú þegar með notenda?
                  <p @click="showSignupForm = false" style="color: #07C157; cursor: pointer;" class="forgot-password">
                    Skráðu þig inn hér
                  </p>
                </div>


              </div>

            </div>
          </transition>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { ref, watch } from 'vue';
import { Icon } from "@iconify/vue";
import { mapGetters } from "vuex";
import { useClickOutside } from "@/composables/useClickOutside";
import { auth } from "../../firebaseDb";

const props = {
  show: {
    type: Boolean,
    default: false,
  },
};
export default {
  name: 'LoginModal',
  props,
  components: {
    Icon
  },
  data() {
    return {
      showOK: false,
      showError: false,
      showInUseAlert: false,
      showWrongAlert: false,
      confirmPass: "",
      showSignupForm: false,
      showForgotForm: false,
      showNoPassAlert: false,
      showNoUserAlert: false,
      user: {
        name: "",
        email: "",
        password: "",
      },
    };
  },
  setup(props) {
    const modal = ref(null);
    const { onClickOutside } = useClickOutside();

    const showLogin = ref(false);

    function closeModal() {
      showLogin.value = false;
    }

    onClickOutside(modal, () => {
      if (showLogin.value === true) {
        closeModal();
      }
    });

    watch(
      () => props.show,
      show => {
        showLogin.value = true;
      },
    );

    return {
      closeModal,
      showLogin,
      modal
    };
  },

  computed: {
    ...mapGetters({
      beepruser: "beepruser",
    }),
  },
  methods: {
    loginEmail() {
      auth.loginEmail(this.user.email, this.user.password).then((res) => {
        if (res.includes("wrong-password")) {
          this.showNoPassAlert = true;
        } else if (res.includes("user-not-found")) {
          this.showNoUserAlert = true;
        } else {
          this.closeModal();
        }
      });
    },

    login() {
      auth.login();
      this.closeModal();
    },

    userRegistration() {
      auth
        .signUpWithEmail(this.user.name, this.user.email, this.user.password)
        .then((res) => {
          if (res.includes("already-in-use")) {
            this.showInUseAlert = true;
          } else if (res != "ok") {
            this.showWrongAlert = true;
          } else {
            this.closeModal();
          }
        });
    },

    forgetPassword() {
      auth
        .resetEmail(this.user.email)
        .then((res) => {
          console.log(res);
          //alert("Tjek din email for at nulstille dit passord!");
          this.user = {
            email: "",
          };
          if (res == "ok") {
            this.$notify({ group: "foo", title: "Reset password", text: "Reset password link has been sent" },2000 );
          } else {
            this.$notify({ group: "error", title: "Reset password", text: "Reset password link could NOT be sent" },2000 );
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
  computed: {
    validatePasswordLength() {
      return this.user.password.length == 0 || this.user.password.length > 5;
    },
    validatePasswords() {
      return (
        this.confirmPass.length == 0 || this.user.password == this.confirmPass
      );
    },
  },

};
</script>

